import { createStore } from "vuex";

const state = {
  token: window.localStorage.getItem("token"),
  name: window.localStorage.getItem("name"),
};
const mutations = {
  SET_TOKEN: (state: any, token: any) => {
    state.token = token;
    localStorage.setItem("token", token);
  },
  SET_NAME: (state: any, name: any) => {
    state.name = name;
    localStorage.setItem("name", name);
  },
  LOG_OUT: (state: any, name: any) => {
    // window.localStorage.remove('token');
    // window.localStorage.remove('name');
    window.localStorage.clear();
    state.name = '';
    state.token = '';
  },
};
const actions = {
  setToken({ commit, state }: { commit: any; state: any }, data: any) {
    console.log(data);
    commit("SET_TOKEN", data);
  },
  setName({ commit, state }: { commit: any; state: any }, data: any) {
    commit("SET_NAME", data);
  },
  logOut({ commit, state }: { commit: any; state: any }, data: any) {
    commit("LOG_OUT");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
