import router from "./router/index";
import store from "@/store/index";
import { ElMessage } from "element-plus";
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(store);
  if (store.getters.token) {
    // 登录状态
    if (to.path === "/login") {
      ElMessage({
        message: "您已登录！",
        type: "success",
      });
      next("/");
    } else {
      next();
    }
  } else {
    // 失去登录
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }

  // if (to.path === "/personalInfo/router2") {
  //   ElMessage({
  //     message: "触发了before路由监听器，router2",
  //     type: "success",
  //   });
  // }
  // next();
});
