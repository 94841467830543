import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home/index.vue";
import { h, resolveComponent } from "vue";

// 首页占位符
const RouterView = {
  render: () => h(resolveComponent("router-view"))
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/myInfo",
    name: "MyInfo",
    component: () => import("@/views/myInfo/index.vue"),
  },
  {
    path: "/404",
    name: "Router404",
    component: () => import("@/views/404/index.vue"),
  },
  {
    path: "/boWen",
    name: "boWen",
    component: () => import("@/views/three/boWen.vue"),
  },

  {
    path: "/",
    name: "default",
    component: () => import("@/components/Layout/HomeLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "codeSnippet",
        name: "CodeSnippet",
        component: RouterView,
        children: [
          {
            path: "",
            component: () => import("@/views/codeSnippet/index.vue"),
          },
          {
            path: "create",
            component: () => import("@/views/codeSnippet/create.vue"),
          },
          {
            path: "edit",
            component: () => import("@/views/codeSnippet/edit.vue"),
          },
          {
            path: "view",
            component: () => import("@/views/codeSnippet/view.vue"),
          },
        ]
      },
      {
        path: "canvas",
        name: "Canvas",
        component: () =>
          import(/* webpackChunkName: "Canvas" */ "@/views/Canvas/index.vue"),
      },
      {
        path: "animation",
        name: "Animation",
        component: () =>
          import(
            /* webpackChunkName: "Canvas" */ "@/views/animation/index.vue"
          ),
      },
      {
        path: "xlsx",
        name: "Xlsx",
        component: () =>
          import(/* webpackChunkName: "Canvas" */ "@/views/xlsx/index.vue"),
      },
      {
        path: "axios",
        name: "Axios",
        component: () =>
          import(/* webpackChunkName: "Canvas" */ "@/views/axios/index.vue"),
      },
      {
        path: "three",
        name: "Three",
        component: () =>
          import(/* webpackChunkName: "Canvas" */ "@/views/three/index.vue"),
      },
      {
        path: "threeQuan",
        name: "threeQuan",
        component: () =>
          import(/* webpackChunkName: "Canvas" */ "@/views/threeQuan/index.vue"),
      },
      {
        path: "myBox",
        name: "myBox",
        component: () =>
          import("@/views/myBox/index.vue"),
      },
      {
        path: "personalInfo",
        name: "PersonalInfo",
        component: () =>
          import(
            /* webpackChunkName: "Canvas" */ "@/views/personalInfo/index.vue"
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Canvas" */ "@/views/personalInfo/myInfo/index.vue"
              ),
            children: [
              {
                path: "router1",
                component: () =>
                  import( "@/views/personalInfo/myInfo/components/LeftRouter/router/router1.vue"),
              },
              {
                path: "router2",
                component: () =>
                  import("@/views/personalInfo/myInfo/components/LeftRouter/router/router2.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   path: "/homeLayout",
  //   name: "HomeLayout",
  //   component: () => import("@/components/Layout/HomeLayout.vue"),
  //   children: [
  //     {
  //       path: "home",
  //       name: "Home",
  //       component: () => import("@/views/Home/index.vue"),
  //     },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
