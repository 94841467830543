import { createApp } from "vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import Loader from "./loader";
import "./assets/styles/index.scss";
import "element-plus/dist/index.css";
import "./assets/scss/index.scss";
import "./permission";

import lazyPlugin from "vue3-lazy";
const app = createApp(App);

// use
app.use(lazyPlugin, {
  loading: require("@/assets/default/noImg.png"), // 加载时默认图片
  error: require("@/assets/default/noImg.png"), // 图片失败时默认图片
});

app.use(ElementPlus);
app.use(Loader);
app.use(store).use(router).mount("#app");
