
import { createApp } from "vue";
import Vue from "vue";
import { createStore } from "vuex";
import getters from "./getters";

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles: any = require.context("./modules", false, /\.ts$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules: any = modulesFiles
  .keys()
  .reduce((modules: any, modulePath: any) => {
    // set './app.js' => 'app'
    const moduleName: any = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
  }, {});
  console.log(1998,modules);
  
const store = createStore({
  modules,
  getters,
});

export default store;
